import React from 'react'
import { MapPin, Smartphone, Mail } from 'react-feather'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import './ContactPageQuickQuote.css'
import FormQuickQuote from '../components/FormQuickQuote'

// Export Template for use in CMS preview
export const ContactQuickQuotePageTemplate = ({
  body,
  title,
  subtitle,
  featuredImage,
  address,
  phone,
  email
}) => (
  <main className="Contact__Quick-Quote">
    <PageHeader
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />
    <section className="section Contact__Quick-Quote--Section1">
      <div className="container Contact__Quick-Quote--Section1--Container">
        <div className="Contact__Quick-Quote__form">
          <FormQuickQuote name="quick-quote" />
        </div>

        <div className="Contact__Quick-Quote__contact">
          <Content source={body} />
          <div className="Contact--Details">
            {address && (
              <a
                className="Contact--Details--Item"
                href={`https://www.google.com.au/maps/search/${encodeURI(
                  address
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MapPin /> {address}
              </a>
            )}
            {phone && (
              <a className="Contact--Details--Item" href={`tel:0243510142`}>
                <Smartphone /> {phone}
              </a>
            )}
            {email && (
              <a className="Contact--Details--Item" href={`mailto:${email}`}>
                <Mail /> {email}
              </a>
            )}
          </div>
        </div>

        
      </div>
    </section>
  </main>
)

const ContactQuickQuotePage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ContactQuickQuotePageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ContactQuickQuotePage

export const pageQuery = graphql`
  query ContactQuickQuotePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        address
        phone
        email
      }
    }
  }
`
