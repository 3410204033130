import React, { Fragment } from 'react'
import { stringify } from 'qs'
import { serialize } from 'dom-form-serializer'

import Recaptcha from 'react-google-recaptcha'

import './Form.css'

class FormQuickQuote extends React.Component {
  static defaultProps = {
    name: 'quick-quote',
    subject: '', // optional subject of the notification email
    action: '',
    successMessage: 'Thanks for your enquiry, we will get back to you soon',
    errorMessage:
      'There is a problem, your message has not been sent, please try contacting us via email'
  }

  state = {
    alert: '',
    disabled: true,
    validPhone: false
  }

  handleOnPhoneChange = event => {
    let isValid = false;
    if (event.target.value) {
      isValid = true;
    }
    this.setState({
      validPhone: isValid
    });
  };

  onChange = value => {
    this.setState({ disabled: false })
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target
    const data = serialize(form)
    
    this.setState({ disabled: true })
    fetch('/quick-quote', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: stringify(data)
    })
      .then(res => {
        if (res.ok) {
          return res
        } else {
          throw new Error('Network error')
        }
      })
      .then(() => {
        form.reset()
        this.setState({
          alert: this.props.successMessage,
          disabled: false
        })
      })
      .catch(err => {
        this.setState({
          disabled: false,
          alert: this.props.errorMessage
        })
      })
  }

  render() {
    const { name, subject, action } = this.props;
    const { validPhone } = this.state;

    return (
      <Fragment>
        <form
          className="Form"
          name={name}
          action={action}
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-recaptcha="true"
        >
          {this.state.alert && (
            <div className="Form--Alert">{this.state.alert}</div>
          )}
          <div className="Form--Group">
            <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="text"
                placeholder="Name"
                name="name"
                required
              />
              <span>Name *</span>
            </label>  
            <label className="Form--Label">
                <input
                className="Form--Input Form--InputText"
                type="email"
                placeholder="Email"
                name="emailAddress"
                required
                />
                <span>Email address *</span>
            </label>          
          </div>          
          
          <div className="Form--Group">
            <label className="Form--Label">
                <input
                type="text"
                placeholder="Phone"
                name="phone"
                className={
                    validPhone ? "Form--Input Form--InputText phone-valid" : "Form--Input Form--InputText phone-invalid"
                }
                onChange={this.handleOnPhoneChange}
                />
                <span>Phone</span>
            </label>
          </div>  
          <div className="Form--Group">
            <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="text"
                placeholder="Pickup location"
                name="pickupLocation"
                required
              />
              <span>Pickup location *</span>
            </label>
            <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="text"
                placeholder="Drop off location"
                name="dropoffLocation"
                required
              />
              <span>Drop off location *</span>
            </label>   
          </div>

          <div className="Form--Group">
            <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="number"
                placeholder="Quantity of pallets"
                name="qtyOfPallets"
                required
              />
              <span>Quantity of pallets *</span>
            </label>
            <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="text"
                placeholder="Approx weight of pallets"
                name="approxPalletsWeight"
                required
              />
              <span>Approx weight of pallets *</span>
            </label>   
          </div>  

                
          <fieldset className="mb-5">
          <p>Forklift at pickup and dropoff location?</p>
            <label className="Form--Label Form--Radio">
              <input
                className="Form--RadioInput"
                type="radio"
                name="forkliftAvailable"
                value="yes"
                defaultChecked
              />
              <span>Yes</span>
            </label>
            <label className="Form--Label Form--Radio">
              <input
                className="Form--RadioInput"
                type="radio"
                name="forkliftAvailable"
                value="no"
              />
              <span>No</span>
            </label>
          </fieldset>

          
          <Recaptcha sitekey="6LdXXLEUAAAAAEbLww_mDRuOULlpIT4G8MrjXhL0" onChange={this.onChange} />

          {!!subject && <input type="hidden" name="subject" value={subject} />}
          <input type="hidden" name="form-name" value={name} />
          <input
            className="Button Form--SubmitButton"
            type="submit"
            value="Send"
            disabled={this.state.disabled}
          />
        </form>
      </Fragment>
    )
  }
}

export default FormQuickQuote
